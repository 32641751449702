import React, { useEffect } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import Header from './header';
import { Container } from './container';
import './layout.scss';
import { UserContext } from '../context/UserContext';
import { Spinner } from './spinner';
import { isBrowser } from '../utils';

const Layout = ({ children, modifier, requireAuth }) => {
  useEffect(() => {
    onPageLoaded();
  }, []);

  const onPageLoaded = () => {
    let gaOptOutLink = document.querySelector('a[href="/gaOptOut"]');

    if (!gaOptOutLink) {
      gaOptOutLink = undefined;
    }

    if (Boolean(gaOptOutLink)) {
      gaOptOutLink.addEventListener('click', event => {
        event.preventDefault();
        console.log('gaOptOutClicked');
        window.gaOptout();
      });
    }
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <UserContext.Consumer>
          {({ loggedIn, error, loading }) => {
            let comp;
            if (requireAuth) {
              if (loading) {
                comp = <Spinner modifier={'dark'} />;
              } else {
                if (loggedIn) {
                  comp = children;
                } else {
                  comp = (
                    <Container modifier="contentWidth">
                      <span>{error || 'Something unexpected happened'}</span>{' '}
                      <Link
                        to="/login"
                        state={{ linkBackTo: isBrowser ? window.location.pathname : '/map' }}
                      >
                        Zum Login
                      </Link>
                    </Container>
                  );
                }
              }
            } else {
              comp = children;
            }
            return (
              <>
                <Header siteTitle={data.site.siteMetadata.title} />
                <Container style={{ padding: '0' }}>
                  <main className={modifier}>{comp}</main>
                </Container>
              </>
            );
          }}
        </UserContext.Consumer>
      )}
    />
  );
};

export default Layout;
